<template>
    <div class="section-form-content">
        <div class="block-content lfont">
            <div class="card" style="padding: 20px;">
                <h4 class="text-center">{{ $t('recruitment.add_new.title') }}</h4>
                <v-form ref="form" v-model="valid" lazy-validation>

                    <div class="section">
                        <p class="text-primary">ສັງລວມຕຳແໜ່ງທີ່ຕ້ອງການ</p>
                        <div class="section-content">
                            <v-row>
                                <v-col cols="12" lg="6" md="6">
                                    <v-autocomplete filled dense item-text="name" item-value="id" :items="positions"
                                        hide-selected :rules="dataRules" persistent-hint requried v-model="item.position_id"
                                        label="ຕຳແໜ່ງທີ່ຕ້ອງການ">
                                        <template v-slot:item="data">
                                            <template>
                                                <v-list-item-content>
                                                    <v-list-item-title v-html="data.item.name"></v-list-item-title>
                                                </v-list-item-content>
                                            </template>
                                        </template>
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="12" lg="6" md="6">
                                    <v-menu ref="menu" v-model="menu" :close-on-content-click="false"
                                        :return-value.sync="date" transition="scale-transition" offset-y max-width="290px"
                                        min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field v-model="item.need_date" label="ວັນທີ່ຕ້ອງການ"
                                                prepend-icon="mdi-calendar" readonly v-bind="attrs"
                                                v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="item.need_date" type="date" no-title scrollable
                                            :rules="dataRules" persistent-hint requried>
                                            <v-spacer></v-spacer>
                                            <v-btn text color="primary" @click="menu = false">
                                                Cancel
                                            </v-btn>
                                            <v-btn text color="primary" @click="$refs.menu.save(item.need_date)">
                                                OK
                                            </v-btn>
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="12" md="6" lg="6">
                                    <v-text-field v-model="item.need_qty" :rules="dataRules" label="ຈຳນວນຕຳແໜ່ງທີ່ຕ້ອງການ"
                                        required type="number"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6" lg="6">
                                    <v-autocomplete filled dense item-text="name" item-value="id" :items="listDepartment"
                                        hide-selected :rules="dataRules" persistent-hint requried
                                        v-model="item.working_at_department_id" label="ພະແນກ/ໜ່ວຍງານ">
                                        <template v-slot:item="data">
                                            <template>
                                                <v-list-item-content>
                                                    <v-list-item-title v-html="data.item.name"></v-list-item-title>
                                                </v-list-item-content>
                                            </template>
                                        </template>
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="12" lg="6" md="6">
                                    <v-textarea label="ສະຖານທີ່ເຮັດວຽກ" :rules="dataRules" class="lfont"
                                        v-model="item.working_place" rows="3"></v-textarea>
                                </v-col>
                            </v-row>
                        </div>
                    </div>


                    <div class="section">
                        <p class="text-primary">ລາຍລະອຽດຕຳແໜ່ງ</p>
                        <div class="section-content">
                            <v-row>
                                <v-col cols="12" lg="6" md="6">
                                    <div style="display: flex;">
                                        <label
                                            style="margin-top: 3px; margin-right: 10px;">ຕຳ​ແໜ່ງ​ດັ່ງ​ກ່າວນີ້​ແມ່ນ:</label>
                                        <v-radio-group v-model="item.reason_id" row style="margin-top: 0px;">
                                            <v-radio :label="item.name" :value="item.id"
                                                v-for="(item, i) in listRecReason"></v-radio>
                                        </v-radio-group>
                                    </div>

                                </v-col>
                                <v-col cols="12" lg="12" md="12">
                                    <div style="display: flex;">
                                        <label style="margin-top: 3px; margin-right: 10px;">ສັນຊາດ:</label>
                                        <v-radio-group v-model="nationality" row style="margin-top: 0px;">
                                            <v-radio :label="item" :value="item"
                                                v-for="(item, i) in nationalities"></v-radio>
                                        </v-radio-group>
                                        <v-text-field label="ຄົນປະເທດ" style="margin-top: 0px; padding-top: 0px;"
                                            v-model="type_nationality" v-if="nationality == 'ຕ່າງປະເທດ'"
                                            :rules="dataRules"></v-text-field>
                                    </div>
                                </v-col>
                                <v-col cols="12" lg="12" md="12">
                                    <div style="display: flex;">
                                        <label style="margin-top: 3px; margin-right: 10px;">ຮູບແບບສັນຍາ:</label>
                                        <v-radio-group v-model="item.contract_type_id" row style="margin-top: 0px;">
                                            <v-radio :label="item.name" :value="item.id"
                                                v-for="(item, i) in listContractTypes"
                                                @change="contract_type_set_period = item.set_period"></v-radio>
                                        </v-radio-group>
                                        <v-text-field label="ໄລ​ຍະ​ຈ້າງງານ​ກໍ​ລະ​ນີ​ສັນ​ຍາ​ຈ້າງ/ທີ່​ປຶກ​ສາ (ເດືອນ)"
                                            style="margin-top: 0px; padding-top: 0px;" v-model="item.qty_month_contract"
                                            v-if="contract_type_set_period" :rules="dataRules"></v-text-field>
                                    </div>
                                </v-col>
                                <v-col cols="12" md="6" lg="6">
                                    <label>ໜ້າທີ່ຮັບພິຊອບ</label>
                                    <v-textarea label="" :rules="dataRules" class="lfont" rows="3"
                                        v-model="item.responsibilities"></v-textarea>
                                </v-col>
                                <v-col cols="12" md="6" lg="6">
                                    <label>ຄຸນນະສົມບັດ</label>
                                    <v-btn icon color="blue" @click="dialogAddProperties = true">
                                        <v-icon>mdi-plus</v-icon>
                                    </v-btn>
                                    <ol style="margin-top: 0px;">
                                        <li v-for="(item, index) in item.properties"
                                            style="display: flex; justify-content: space-between;">{{ index + 1 }}. {{ item
                                            }}
                                            <v-btn icon color="pink" @click="remove_properties(index)">
                                                <v-icon>mdi-trash-can</v-icon>
                                            </v-btn>
                                        </li>
                                    </ol>
                                </v-col>
                            </v-row>
                        </div>
                    </div>

                    <div class="section">
                        <p class="text-primary">ອະນຸມັດໂດຍ</p>
                        <div class="section-content">
                            <v-row>
                                <v-col cols="12" lg="6" md="6">
                                    <v-autocomplete filled dense item-text="name" item-value="id" :items="listEmployees"
                                        hide-selected :rules="dataRules" persistent-hint requried v-model="director"
                                        label="ຜູ້ອໍານວຍການບໍລິສັດ">
                                        <template v-slot:item="data">
                                            <template>
                                                <v-list-item-content>
                                                    <v-list-item-title v-html="data.item.name"></v-list-item-title>
                                                </v-list-item-content>
                                            </template>
                                        </template>
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="12" lg="6" md="6">
                                    <v-autocomplete filled dense item-text="name" item-value="id" :items="listEmployees"
                                        hide-selected :rules="dataRules" persistent-hint requried v-model="hr"
                                        label="ພະແນກ ບຸກຄະລາກອນ">
                                        <template v-slot:item="data">
                                            <template>
                                                <v-list-item-content>
                                                    <v-list-item-title v-html="data.item.name"></v-list-item-title>
                                                </v-list-item-content>
                                            </template>
                                        </template>
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="12" lg="6" md="6"></v-col>
                            </v-row>
                        </div>
                    </div>

                    <br><br>

                    <v-btn color="primary" class="text-white" @click="save_request">
                        Save
                    </v-btn>
                </v-form>
            </div>
            <ModalAddRecruitmentProperties :dialog="dialogAddProperties" @dialog="(val) => dialogAddProperties = (val)"
                @add="(val) => add_properties(val)" />

        </div>
        <Loading v-if="isLoading" />
    </div>
</template>

<script>
import Loading from "@/components/Loading";
import ModalAddRecruitmentProperties from '@/components/Recruitments/ModalAddRecruitmentProperties'
export default {
    components: {
        Loading,
        ModalAddRecruitmentProperties,
    },
    data() {
        return {
            dialogAddProperties: false,
            date: "",
            menu: false,
            isLoading: false,
            valid: true,
            positions: [],
            listEmployees: [],
            listDepartment: [],
            listRecReason: [],
            listContractTypes: [],
            director: "",
            hr: "",
            contract_type_set_period: "",
            nationalities: ["ລາວ", "ຕ່າງປະເທດ"],
            nationality: "ລາວ",
            type_nationality: "",
            item: {
                position_id: "",
                need_date: "",
                need_qty: 1,
                working_at_department_id: "",
                working_place: "",
                reason_id: "",
                nationality: "ລາວ",
                contract_type_id: "",
                qty_month_contract: "",
                responsibilities: "",
                note: "",
                properties: [],
                approver: []
            },
            server_errors: {
                position_id: "",
                need_date: "",
                need_qty: 1,
                working_at_department_id: "",
                working_place: "",
                reason_id: "",
                nationality: "",
                contract_type_id: "",
                qty_month_contract: "",
                responsibilities: "",
                note: "",
                properties: [],
                approver: []
            },

            dataRules: [
                v => !!v || 'ກະລຸນາຂໍ້ມູນ!',
            ],
        }
    },
    methods: {
        save_request() {
            this.item.approver = [];
            this.item.approver.push({ id: this.director, position: "director" }, { id: this.hr, position: "hr" });
            if (this.nationality == 'ລາວ' || this.nationality == '') {
                this.item.nationality = this.nationality;
            } else {
                this.item.nationality = this.type_nationality;
            }

            this.isLoading = true;
            this.$axios
                .post(`employee/recruitment/request`, this.item)
                .then((res) => {
                    this.isLoading = false;
                    if (res.status === 200) {
                        setTimeout(() => {
                            this.$notification.OpenNotification_AddItem_OnSuccess(
                                "top-right",
                                "primary",
                                3000
                            );
                        }, 300);
                        this.$router
                            .push({
                                name: "recruitment.request",
                            })
                            .catch(() => { });
                    }
                })
                .catch((error) => {
                    this.isLoading = false;
                    if (error.response.status === 422) {
                        let obj = error.response.data.errors;
                        if (!obj) {
                            obj = error.response.data;
                            let res = "";
                            for (let [key, value] of Object.entries(obj)) {
                                res = value;
                            }
                            this.$notification.ShowErrors("top-right", "danger", 3000, res);
                        } else {
                            for (let [key, value] of Object.entries(obj)) {
                                this.server_errors[key] = value[0];
                                this.$notification.ShowErrors(
                                    "top-right",
                                    "danger",
                                    3000,
                                    (this.server_errors[key] = value[0])
                                );
                            }
                        }
                    }
                });
        },
        remove_properties(index) {
            this.item.properties.splice(index, 1);
        },
        add_properties(data) {
            let check = this.item.properties.filter(item => item == data);
            if (check.length > 0) return;
            this.item.properties.push(data);
        },
        validate() {
            this.$refs.form.validate()
        },
        resetValidation() {
            this.$refs.form.resetValidation()
        },
        fetchPosition() {
            this.$axios
                .get(`company/all-position`)
                .then((res) => {
                    if (res.data.code === 200) {
                        this.positions = res.data.data;
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        fetchDepartment() {
            this.$axios.get(`/employee/list/departments`).then((res) => {
                if (res.status === 200) {
                    this.listDepartment = res.data.data;
                }
                this.isLoading = false;
            });
        },
        fetchRecruitmentReason() {
            this.$axios.get(`/employee/recruitment/reason`).then((res) => {
                if (res.status === 200) {
                    this.listRecReason = res.data.data;
                }
                this.isLoading = false;
            });
        },
        fetchRecruitmentContractType() {
            this.$axios.get(`/employee/recruitment/contract-type`).then((res) => {
                if (res.status === 200) {
                    this.listContractTypes = res.data.data;
                }
                this.isLoading = false;
            });
        },
        fetchEmployees() {
            this.$axios.get(`/company/all/employee-name`).then((res) => {
                if (res.status === 200) {
                    this.listEmployees = res.data.data;
                }
                this.isLoading = false;
            });
        },
    },
    created() {
        this.fetchPosition();
        this.fetchDepartment();
        this.fetchRecruitmentReason();
        this.fetchRecruitmentContractType();
        this.fetchEmployees();
    },
}
</script>

<style lang="scss" scoped>
.text-white {
    color: white !important;
}

.section {
    padding: 20px 0px;

    .section-content {
        padding: 0px 50px;
    }
}
</style>